import React, { useState, forwardRef } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventDateTime = ({ dateTimeData, stopSellingAtStart, handleDateTimeAdd, handleDateTimeDelete, onChangeDate, onChangeStartTime, onChangeEndTime, onChangeCheckBox }) => {
  const [reload, setReload] = useState(0)

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text" readOnly value={value} onClick={onClick} ref={ref} className="form-control form-control-lg" placeholder="Event date" />
  ));

  const filterTime = (dtt, stdt) => {
    const isPastTime = stdt > dtt;
    return !isPastTime;
  };

  return (
    <>
      {dateTimeData.length >= 1 && (
        <>
          <div className='row'>
            <div className='col-lg-6'>
              <div class="mb-3 form-check">
                <input type="checkbox" onChange={(e) => {
                  onChangeCheckBox(true)
                  setReload(reload + 1)
                }} checked={stopSellingAtStart === true ? true : false} class="form-check-input" id={`exampleCheck1`} />
                <label class="form-check-label" for={`exampleCheck1`}>Stop selling tickets on first day of Event</label>
              </div>
            </div>
            <div className='col-lg-6'>
              <div class="mb-3 form-check">
                <input type="checkbox" onChange={(e) => {
                  onChangeCheckBox(false)
                  setReload(reload + 1)
                }} checked={stopSellingAtStart === false ? true : false} class="form-check-input" id={`endexampleCheck2`} />
                <label class="form-check-label" for={`endexampleCheck2`}>Stop selling tickets on Last day of Event</label>
              </div>
            </div>
          </div>
          {dateTimeData.map((item, index) => {
            const ind = index + 1
            return (
              <>
                <div className="mb-3">
                  <label htmlFor="floatingInput3" className="form-label">{`Event Date ${ind}`}</label>
                  <DatePicker withPortal dateFormat="yyyy-MM-dd" selected={item.date} disabledKeyboardNavigation showMonthDropdown
                    useLongMonthInDropdown minDate={new Date()} customInput={<DateCustomInput />} onChange={(date) => {
                      onChangeDate(index, date)
                      setReload(reload + 1)
                    }} />
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className="mb-3">
                      <label htmlFor="floatingInput3" className="form-label">{`Event Start Time ${ind}`}</label>
                      <DatePicker selected={item.startTime} disabledKeyboardNavigation showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa" customInput={<DateCustomInput />} onChange={(time) => {
                          onChangeStartTime(index, time)
                          setReload(reload + 1)
                        }} />
                    </div>
                  </div>
                  <div className='col-lg-6' >
                    <div className="mb-3">
                      <label htmlFor="floatingInput3" className="form-label">{`Event End Time ${ind}`}</label>
                      <DatePicker selected={item.endTime} disabledKeyboardNavigation showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        // filterTime={tt => filterTime(tt, item.startTime)}
                        dateFormat="h:mm aa" customInput={<DateCustomInput />} onChange={(time) => {
                          onChangeEndTime(index, time)
                          setReload(reload + 1)
                        }} />
                    </div>
                  </div>
                </div>

                {dateTimeData.length === ind && dateTimeData.length >= 1 && (
                  <button type="button" onClick={() => handleDateTimeAdd(ind)} className="btn btn-square btn-primary m-2 mb-2">
                    <i className="fas fa-plus"></i>
                  </button>
                )}
                {dateTimeData.length > 1 && (
                  <button type="button" onClick={() => {
                    handleDateTimeDelete(index)
                    setReload(reload + 1)
                  }} className="btn btn-square btn-primary m-2 mb-2 ml-3">
                    <i className="fas fa-trash"></i>
                  </button>
                )}
              </>
            )
          }
          )}
        </>
      )}
    </>
  )
}

export default EventDateTime
