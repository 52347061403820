import React from 'react'
import { Helmet } from "react-helmet";

const Profile = () => {
  return (
    <div>Profile</div>
  )
}

export default Profile
