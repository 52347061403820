import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import authorizeGetRequest from '../api/authorizeGetRequest';

const Wallet = () => {
  const [showForm, setshowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [funds, setFunds] = useState({
    totalFunds : 0,
    withdrawableBalance : 0,
    currency: 'UGX',
  })

  useEffect(() => {
    const data = async () => {
      setLoading(true)
      await authorizeGetRequest(`payments/getWalletBalance`)
        .then((response) => {
          console.log('getWalletBalance success', response)
          let totalFunds = 0, withdrawableBalance = 0, currency;
          response.revenue.forEach(element => {
            totalFunds = totalFunds + element.revenue
            withdrawableBalance = withdrawableBalance + element.withdrawableBalance
            currency = element.currency
          });
          setFunds({
            totalFunds : totalFunds,
            withdrawableBalance : withdrawableBalance,
            currency: currency,
          })
          setLoading(false)
        }).catch((error) => {
          console.log('getWalletBalance failed', error)
          setLoading(false)
        });
    }
    data()
  }, [])

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
          <div className="col-sm-6 col-xl-6">
            <div className="bg-secondary rounded d-flex align-items-center justify-content-start p-4">
              <i className="fa fa-briefcase fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Total Balance</p>
                <h6 className="mb-0">{`${funds.totalFunds} ${funds.currency}`}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-6">
            <div className="bg-secondary rounded d-flex align-items-center justify-content-start p-4">
              <i className="fa fa-briefcase fa-3x text-primary"></i>
              <div className="ms-3">
                <p className="mb-2">Withdrawable funds</p>
                <h6 className="mb-0">{`${funds.withdrawableBalance} ${funds.currency}`}</h6>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="rounded d-flex align-items-center justify-content-between p-4">
              <button type="button" className="btn btn-primary" onClick={() => setshowForm(true)}><i className="fa fa-credit-card me-2"></i>Withdraw</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-4 px-4">
        <div className="vh-100 bg-secondary rounded mx-0 p-4">
          {loading ? (
            <>
              <div id="spinner" className="show bg-dark position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {showForm ? (
                <>
                  <div className='col-lg-12 row align-items-center justify-content-center text-center'>
                    <h6 className="mb-4">Send a withdraw request</h6>
                    <div className='col-lg-6'>
                      <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput"
                          placeholder="name@example.com" />
                        <label for="floatingInput">Amount</label>
                      </div>
                      <button type="submit" onClick={()=> alert("You cannot withdraw at this time, contact support")} className="btn btn-primary py-3 w-100 mb-4">Withdraw funds</button>

                      <button type="button" onClick={() => setshowForm(false)} className="btn btn-lg btn-lg-square btn-primary m-2"><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="table-responsive mt-4">
                    <h6 className="mb-4">Transaction History</h6>
                    <table className="table table-dark">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Title</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Country</th>
                          <th scope="col">ZIP</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colSpan={7} className='text-center' scope="row">Records will appear here</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Wallet
