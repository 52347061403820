import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/Error';
import Home from './pages/Home';
import SignIn from './pages/SignIn';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <Home page="events" />,
      errorElement: <Error />,
    },
    {
      path: "/signin",
      element: <SignIn />,
      errorElement: <Error />,
    },
    {
      path: "/wallet",
      element: <Home page="wallet" />,
      errorElement: <Error />,
    },
    {
      path: "/support",
      element: <Home page="support" />,
      errorElement: <Error />,
    },
    {
      path: "/profile",
      element: <Home page="profile" />,
      errorElement: <Error />,
    },
    {
      path: "/scanner",
      element: <Home page="scanner" />,
      errorElement: <Error />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
