import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid pt-4 px-4">
      <div className="bg-secondary rounded-top p-4">
        <div className="row">
          <div className="col-12 col-sm-6 text-center text-sm-start">
            &copy; <Link to="/">Tickets2Go</Link>, All Rights Reserved.
          </div>
          <div style={{display: 'none'}} className="col-12 col-sm-6 text-center text-sm-end">
            Designed By <a href="https://htmlcodex.com">HTML Codex</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
