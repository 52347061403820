import { NavLink, Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar pe-4 pb-3">
      <nav className="navbar bg-secondary navbar-dark">
        <Link to="/" className="navbar-brand mx-4 mb-3">
          <h3 className="text-primary">Tickets2Go</h3>
        </Link>
        <div className="navbar-nav w-100">
          {/* <NavLink to="/dashboard" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}><i className="fa fa-tachometer-alt me-2"></i>Dashboard</NavLink> */}
          <NavLink to="/" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}><i className="fa fa-calendar-alt me-2"></i>My Events</NavLink>
          <NavLink to="/wallet" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}><i className="fa fa-wallet me-2"></i>My Wallet</NavLink>
          <NavLink to="/scanner" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}><i className="fa fa-qrcode me-2"></i>Ticket Scanner</NavLink>
          <NavLink to="/support" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}><i className="fa fa-question-circle me-2"></i>Support</NavLink>
        </div>
      </nav>
    </div>
  )
}

export default Sidebar
