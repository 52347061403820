import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Events from "./Events";
import Support from "./Support";
import Wallet from "./Wallet";
import Profile from "./Profile";
import Scanner from "./Scanner";


const Home = ({page}) => {
  let navigate = useNavigate();

  useEffect(() => {
    const getData = () => {
      if (!localStorage.tickets2GoAdminToken){
        return navigate("/signin");
      }
    }
    getData()
  }, [page])
  

  return (
    <div className="container-fluid position-relative d-flex p-0">

      <Sidebar />

      <div className="content">

        <Navbar />

        {page === 'dashboard' && (
          <div className="container-fluid pt-4 px-4">
            <div className="row vh-100 bg-secondary rounded align-items-center justify-content-center mx-0">
              <div className="col-md-6 text-center">
                <h3>This is dashboard page</h3>
              </div>
            </div>
          </div>
        )}
        {page === 'events' && (
          <Events />
        )}
        {page === 'wallet' && (
          <Wallet />
        )}
        {page === 'scanner' && (
          <Scanner />
        )}
        {page === 'support' && (
          <Support />
        )}
        {page === 'profile' && (
          <Profile />
        )}

        <Footer />

      </div>

      {/* <!-- Content End --> */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default Home
