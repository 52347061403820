import React, { useState } from 'react'

const TicketType = ({ ticketTypeData, handleTicketAdd, handleTicketDelete, onChangeTicketType, onChangeTicketPrice }) => {
  const [reload, setReload] = useState(0)

  return (
    <>
      {ticketTypeData.length >= 1 && (
        <>
          {ticketTypeData.map((item, index) => {
            const ind = index + 1
            return (
              <>
                <div key={index} className="mb-2">
                  <label htmlFor="floatingInput1">Ticket Type</label>
                  <input type="text" value={item.type} onChange={(e) => {
                    onChangeTicketType(index, e.target.value)
                    setReload(reload + 1)
                  }} className="form-control form-control-lg" style={{ backgroundColor: "#E5E5E5" }} id="floatingInput1" placeholder="For example : Free, Early bird, Ordinary, VIP" required />
                </div>
                <div className="mb-2">
                  <label htmlFor="floatingInput1">Ticket Price</label>
                  <input type="number" value={item.price} onChange={(e) => {
                    onChangeTicketPrice(index, e.target.value)
                    setReload(reload + 1)
                  }} className="form-control form-control-lg" style={{ backgroundColor: "#E5E5E5" }} id="floatingInput1" placeholder="100" required />
                </div>

                {ticketTypeData.length === ind && ticketTypeData.length >= 1 && (
                  <button type="button" onClick={() => handleTicketAdd(ind)} className="btn btn-square btn-primary m-2 mb-2">
                    <i className="fas fa-plus"></i>
                  </button>
                )}
                {ticketTypeData.length > 1 && (
                  <button type="button" onClick={() => {
                    handleTicketDelete(index)
                    setReload(reload + 1)
                  }} className="btn btn-square btn-primary m-2 mb-2 ml-3">
                    <i className="fas fa-trash"></i>
                  </button>
                )}
              </>
            )
          }
          )}
        </>
      )}
    </>
  )
}

export default TicketType
