import React from 'react'
import { Helmet } from "react-helmet";
import {QrScanner} from '@yudiel/react-qr-scanner';

const Scanner = () => {
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://vendor.tickets2go.net/" />
        <meta property="og:title" content="Ticket Scanner - Tickets2Go" />
        <meta property="og:description"
          content="Verify tickets here" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://vendor.tickets2go.net/" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
   
    </Helmet>
    <div className="container-fluid pt-4 px-4">
      <div className="row vh-100 bg-secondary rounded align-items-center justify-content-center mx-0">
        <div className="col-md-6 text-center">
        <QrScanner
          onDecode={(result) => console.log('QrScanner', result)}
          onError={(error) => console.log('QrScanner error', error?.message)}
      />
        </div>
      </div>
    </div>
    </>
  )
}

export default Scanner
