import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import PasswordMask from 'react-password-mask';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import ReactCodeInput from 'react-verification-code-input';
import { logEvent } from "firebase/analytics";
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, provider, analytics } from '../firebaseConfig';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';
import authorizePostMultipartWOT from '../api/authorizePostMultipartWOT';

const SignIn = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isSigningIn, setisSigningIn] = useState('sign')
  const [loginMsg, setLoginMsg] = useState({
    err: false,
    msg: '',
  })
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })
  const onChangeFN = (e) => {
    setFirstName(e.target.value);
  }
  const onChangeLN = (e) => {
    setLastName(e.target.value);
  }
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const signInWithGoogle = async () => {
    setLoading(true)
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const nm = user.displayName.split(" ")
        // console.log('google result', nm, user)
        const dt = {
          firstName: nm[0],
          lastName: nm[1],
          email: user.email,
          usrLevel: 'vendor',
          uid: user.uid,
        }
        authorizePostRequestWOT('user/register', dt)
          .then(response => {
            // console.log('register message', response);
            setLoginMsg({
              err: false,
              msg: 'success',
            })
            localStorage.setItem('tickets2GoAdminToken', response.token);
            localStorage.setItem('tickets2GoAdmin', JSON.stringify(response.user));
            // checkUser()
            // setReloadNav(reloadNav + 1)
            setLoading(false)
            return navigate("/");
          })
          .catch((error) => {
            // console.log('error logging in', error)
            setLoginMsg({
              err: true,
              msg: error.message || 'something went wrong, try again',
            })
            setLoading(false)
          });
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        const dt = {
          errorCode,
          errorMessage,
          email,
          credential,
        }
        // console.log('google error', dt)
        setLoginMsg({
          err: true,
          msg: errorMessage || 'something went wrong, try again',
        })
        setLoading(false)
      });
  }
  const onSubmitSignIn = async (e) => {
    e.preventDefault()
    setLoading(true)
    setLoginMsg({
      err: false,
      msg: '',
    })
    if (isSigningIn === 'sign') {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // const user = userCredential.user;
          const dt = {
            email,
          }
          authorizePostRequestWOT('user/login', dt)
            .then(response => {
              // console.log('login message', response);
              setLoginMsg({
                err: false,
                msg: 'success',
              })
              localStorage.setItem('tickets2GoAdminToken', response.token);
              localStorage.setItem('tickets2GoAdmin', JSON.stringify(response.user));
              setLoading(false)
              return navigate("/");
            })
            .catch((error) => {
              // console.log('error logging in', error)
              setLoginMsg({
                err: true,
                msg: error.message || 'something went wrong, try again',
              })
              setLoading(false)
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const msg = {
            errorCode,
            errorMessage
          }
          // console.log('err sign in firebase', msg)
          setLoginMsg({
            err: true,
            msg: errorMessage,
          })
          setLoading(false)
        });
    } else if (isSigningIn === 'create') {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // const user = userCredential.user;
          const dt = {
            firstName,
            lastName,
            email,
            usrLevel: 'vendor',
            uid: userCredential.user.uid,
          }
          authorizePostRequestWOT('user/register', dt)
            .then(response => {
              // console.log('register message', response);
              setLoginMsg({
                err: false,
                msg: 'success',
              })
              localStorage.setItem('tickets2GoAdminToken', response.token);
              localStorage.setItem('tickets2GoAdmin', JSON.stringify(response.user));
              setLoading(false)
              return navigate("/");
            })
            .catch((error) => {
              // console.log('error logging in', error)
              setLoginMsg({
                err: true,
                msg: error.message || 'something went wrong, try again',
              })
              setLoading(false)
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const msg = {
            errorCode,
            errorMessage
          }
          // console.log('err sign up firebase', msg)
          setLoginMsg({
            err: true,
            msg: errorMessage,
          })
          setLoading(false)
        });
    } else if (isSigningIn === 'reset') {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
        setLoginMsg({
          err: false,
          msg: 'Check your email for furthur instructions',
        })
        setEmail('')
        setLoading(false)
      } catch (err) {
        // console.error(err);
        // alert(err.message);
        setLoginMsg({
          err: true,
          msg: err.message,
        })
        setLoading(false)
      }
    }

  }

  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://vendor.tickets2go.net/" />
        <meta property="og:title" content="Vendor - Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://vendor.tickets2go.net/" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
   
    </Helmet>
    <div className="container-fluid position-relative d-flex p-0">

      <div className="container-fluid">
        <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
          <div className="col-12 col-sm-8 col-md-6 col-lg-6">

            <h3 className="text-primary text-center">{isSigningIn === 'sign' ? 'Sign in - Tickets2Go' : isSigningIn === 'create' ? 'Register - Tickets2Go' : 'Reset Password - Tickets2Go'}</h3>

            <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
              {loading ? (
                <>
                  <div class="loader m-5 text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-floating mb-3 d-flex align-items-center justify-content-center">

                    <div onClick={() => signInWithGoogle()} class="google-button">
                      <div class="icon">
                        {/* <img src="google-icon.png" alt="Google Icon"> */}
                        <a href="" className="iconimg text-white">
                          <span className="bi-google"></span>
                        </a>
                      </div>
                      <div class="textgg ml-2">
                        Continue with Google
                      </div>
                    </div>
                  </div>
                  <p className='mt-2 text-center text-primary'>--- Or Use your Email ---</p>
                  <p className={loginMsg.err ? 'text-danger' : 'text-primary'}>{loginMsg.msg}</p>
                  <form method='post' onSubmit={onSubmitSignIn}>
                    {isSigningIn === 'create' && (
                      <>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="floatingInput" placeholder="First Name" value={firstName} name="firstName" required onChange={onChangeFN} />
                          <label for="floatingInput">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" className="form-control" id="floatingInput" placeholder="Last Name" value={lastName} name="lastName" required onChange={onChangeLN} />
                          <label for="floatingInput">Last Name</label>
                        </div>
                      </>
                    )}
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={email} required onChange={onChangeEmail} />
                      <label for="floatingInput">Email address</label>
                    </div>
                    {isSigningIn === 'sign' || isSigningIn === 'create' ? (
                      <div className='form-group mt-2 mb-2'>
                        <label htmlFor='subject'>Password</label>
                        <PasswordMask
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          inputClassName='form-control form-control-lg'
                          required
                        />
                      </div>
                    ) : (<></>)}
                    {isSigningIn === 'sign' && (
                      <p style={{ cursor: 'pointer' }} className='text-danger mt-1' onClick={() => {
                        setisSigningIn('reset')
                        setLoginMsg({
                          err: false,
                          msg: '',
                        })
                      }}>I forgot my password</p>
                    )}
                    <button type="submit" className="btn btn-primary py-3 w-100 mb-4">{isSigningIn === 'sign' ? 'Sign in' : isSigningIn === 'create' ? 'Create Account' : 'Reset Password'}</button>

                  </form>
                  <p style={{ cursor: 'pointer' }} onClick={() => {
                    if (isSigningIn === 'sign') {
                      setisSigningIn('create')
                    } else {
                      setisSigningIn('sign')
                    }
                    setLoginMsg({
                      err: false,
                      msg: '',
                    })
                  }} className="text-center mb-0">
                    {isSigningIn === 'sign' ? (<>Don't have an account? <a href="#">Create one</a></>) : (<>Have an account? <a href="#">Sign in</a></>)}

                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default SignIn
