import React from 'react'
import { Helmet } from "react-helmet";

const Support = () => {
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://vendor.tickets2go.net/" />
        <meta property="og:title" content="Vendor - Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://vendor.tickets2go.net/" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
   
    </Helmet>
    <div className="container-fluid pt-4 px-4">
      <div className="row vh-100 bg-secondary rounded align-items-center justify-content-center mx-0">
        <div className="col-md-6 text-center">
          <h3>Kindly email info@tickets2go.net</h3>
          <h3>Whatsapp/Call +256770452110</h3>
        </div>
      </div>
    </div>
    </>
  )
}

export default Support
