import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  let navigate = useNavigate();
  const [userData, setUserData] = useState('')
  const [sidebarToggler, setSidebarToggler] = useState(false)
  const [sidebarTogglerCount, setSidebarTogglerCount] = useState(0)
  

  useEffect(() => {
    const getData = async () => {
      setUserData(JSON.parse(localStorage.getItem('tickets2GoAdmin')))
      // console.log('user', JSON.parse(localStorage.getItem('tickets2GoUser')))
    }
    getData();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
        {/* <a href="index.html" className="navbar-brand d-flex d-lg-none me-4">
          <h2 className="text-primary mb-0"><i className="fa fa-user-edit"></i></h2>
        </a> */}
        <a href="#" onClick={async () => {
          setSidebarTogglerCount(sidebarTogglerCount + 1)
          console.log('sidebar count', sidebarTogglerCount, sidebarToggler)
          if(sidebarTogglerCount >= 1){
            setSidebarToggler(!sidebarToggler)
          }
          await window.sidebarToggler();
        }} className="sidebar-toggler flex-shrink-0">
          {sidebarToggler ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
        </a>
        <div className="navbar-nav align-items-center ms-auto">
          {/* <div className="nav-item dropdown">
            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
              <i className="fa fa-bell me-lg-2"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
              <a href="#" className="dropdown-item">
                <h6 className="fw-normal mb-0">Profile updated</h6>
                <small>15 minutes ago</small>
              </a>
              <hr className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <h6 className="fw-normal mb-0">New user added</h6>
                <small>15 minutes ago</small>
              </a>
              <hr className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <h6 className="fw-normal mb-0">Password changed</h6>
                <small>15 minutes ago</small>
              </a>
              <hr className="dropdown-divider" />
              <a href="#" className="dropdown-item text-center">See all notifications</a>
            </div>
          </div> */}
          <div className="nav-item dropdown">
            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
              {/* <img className="rounded-circle me-lg-2" src={userData.avatar} alt="" style={{ width: 40, height: 40 }} /> */}
              <span className="d-none d-lg-inline-flex">{`${userData.firstName} ${userData.lastName}`}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
              <Link to="/profile" className="dropdown-item">My Profile</Link>
              <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="dropdown-item">Log Out</a>
            </div>
          </div>
        </div>
      </nav>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary" id="exampleModalLabel">Log Out ?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Confirm Logout
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" onClick={() => {
                localStorage.clear()
                navigate("/signin");
              }} data-bs-dismiss="modal" className="btn btn-primary">Yes, proceed</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
