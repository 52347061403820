import { useRouteError, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"


const Error = () => {
  const error = useRouteError();

  return (
    <div className="container-fluid position-relative d-flex p-0">

      <Sidebar />

      <div className="content">

        <Navbar />

        <div className="container-fluid pt-4 px-4">
          <div className="row vh-100 bg-secondary rounded align-items-center justify-content-center mx-0">
            <div className="col-md-6 text-center p-4">
              <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 className="display-1 fw-bold">{error.status}</h1>
              <h1 className="mb-4">{error.statusText || error.message}</h1>
              <p className="mb-4">{error.data}</p>
              <Link to="/" className="btn btn-primary rounded-pill py-3 px-5">Go Back To Home</Link>
            </div>
          </div>
        </div>

        <Footer />

      </div>
      {/* <!-- Content End --> */}

      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default Error
