import React, { useState } from 'react'

const OnlineEventURL = ({ onlineEventUrls, handleUrlAdd, handleUrlDelete, onChangeUrl }) => {
  const [reload, setReload] = useState(0)

  return (
    <>
      {onlineEventUrls.length >= 1 && (
        <>
          {onlineEventUrls.map((item, index) => {
            const ind = index + 1
            return (
              <div key={index}>
                <div className="form-floating">
                  <input type="text" value={item.url} onChange={(e) => {
                    onChangeUrl(index, e.target.value)
                    setReload(reload + 1)
                  }} className="form-control" style={{ backgroundColor: "#E5E5E5" }} id="floatingInput1" placeholder="My event title" required={onlineEventUrls.length > 1 ? true : false} />
                  <label htmlFor="floatingInput1">Link to Online Event</label>
                </div>
                {onlineEventUrls.length === ind && onlineEventUrls.length >= 1 && (
                  <button type="button" onClick={() => handleUrlAdd(ind)} className="btn btn-square btn-primary m-2 mb-2">
                    <i className="fas fa-plus"></i>
                  </button>
                )}
                {onlineEventUrls.length > 1 && (
                  <button type="button" onClick={() => {
                    handleUrlDelete(index)
                    setReload(reload + 1)
                  }} className="btn btn-square btn-primary m-2 mb-2 ml-3">
                    <i className="fas fa-trash"></i>
                  </button>
                )}
              </div>
            )
          }
          )}
        </>
      )}
    </>
  )
}

export default OnlineEventURL
