import { useState, forwardRef, useEffect } from 'react'
import Swal from 'sweetalert2';
import { format } from 'date-fns'
import { Helmet } from "react-helmet";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';
import authorizePostMultipartWOT from '../api/authorizePostMultipartWOT';
import authorizeGetRequest from '../api/authorizeGetRequest';
import authorizePatchRequest from '../api/authorizePatchRequest';
import authorizePostMultipart from '../api/authorizePostMultipart';
import '../App.css'
import OnlineEventURL from '../components/OnlineEventURL';
import EventDateTime from '../components/EventDateTime';
import TicketType from '../components/TicketType';
import authorizePatchMultipart from '../api/authorizePatchMultipart';
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';

const Events = () => {
  const [showForm, setshowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [img, setImg] = useState([])
  const [imgPreview, setImgPreview] = useState([]);
  const [description, setDescription] = useState('')
  const [eventName, setEventName] = useState('')
  const [eventLocation, setEventLocation] = useState('')
  const [data, setData] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [imgerr, setImgerr] = useState('')
  const [wordCount, setWordCount] = useState(155)
  const [noOfTickets, setNoOfTickets] = useState()
  const [eventType, setEventType] = useState('')
  const [placeErr, setPlaceErr] = useState('')
  const [formStep, setFormStep] = useState('1')
  const [step2Available, setstep2Available] = useState(false)
  const [step3Available, setstep3Available] = useState(false)
  const [eventUrlContent, setEventUrlContent] = useState([
    { id: 0, url: '' }
  ])
  const [eventDuration, setEventDuration] = useState('')
  const [eventDurationErr, setEventDurationErr] = useState('')
  const [multipleEventDateTime, setMultipleEventDateTime] = useState([
    { id: 0, date: new Date(), startTime: new Date(), endTime: new Date(), },
    { id: 1, date: new Date(), startTime: new Date(), endTime: new Date(), }
  ])
  const [ticketType, setTicketType] = useState([
    { id: 0, type: '', price: 0 }
  ])
  const [stopSellingAtStart, setStopSellingAtStart] = useState(true)
  const [currency, setCurrency] = useState('')
  const [currencyErr, setCurrencyErr] = useState('')
  const [noOfTicketsErr, setNoOfTicketsErr] = useState('')
  const [publishEvent, setPublishEvent] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [eventDetails, setEventDetails] = useState('')
  const [isEdit, setIsEdit] = useState(false);
  const [imgEdits, setImgEdits] = useState({})
  const [allowedCurrencies, setAllowedCurrencies] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      await authorizeGetRequest('event/vendor/0')
        .then(response => {
          // console.log('events', response.events);
          setData(response.events)
        })
        .catch((error) => {
          // console.log('error logging in', error)
          setData([])
        })
        authorizeGetRequestWOT(`payments/getCurrencies`)
        .then(response => {
          // console.log('currencies', response.currencies);
          setAllowedCurrencies(response.currencies)
        })
        .catch((error) => {
          // console.log('error logging in', error)
          setAllowedCurrencies([])
        })
      setLoading(false)
    }
    getData()
  }, [])

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text" readOnly value={value} onClick={onClick} ref={ref} className="form-control form-control-lg" placeholder="Event date" />
  ));

  const handleImgChange = e => {
    // console.log('img data', e.target.files)
    setImgerr('')
    if (e.target.files.length > 3) {
      setImgerr(`Only the first 3 images will be uploaded, Reselect your best 3 to change`)
    }
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      const imgss = [], imgup = []
      for (let index = 0; index < 3; index++) {
        if (index < e.target.files.length) {
          imgss.push(URL.createObjectURL(e.target.files[index]));
          imgup.push(e.target.files[index]);
        }
      }
      setImg(imgup);
      setImgPreview(imgss);
    };
  }
  const handleUrlAdd = async (id) => {
    await setEventUrlContent([...eventUrlContent, { id: id, url: '' }])
  }
  const handleUrlDelete = async (id) => {
    let cont = eventUrlContent
    const indexOfObject = cont.findIndex(object => {
      return object.id === id;
    });
    await cont.splice(indexOfObject, 1);
    await setEventUrlContent(cont)
  }
  const onChangeUrl = async (id, item) => {
    let cont = eventUrlContent
    cont[id].url = item
    await setEventUrlContent(cont)
  }
  const handleDateTimeAdd = async (id) => {
    await setMultipleEventDateTime([...multipleEventDateTime, { id: id, date: new Date(), startTime: new Date(), endTime: new Date(), }])
  }
  const handleDateTimeDelete = async (id) => {
    let cont = multipleEventDateTime
    const indexOfObject = cont.findIndex(object => {
      return object.id === id;
    });
    await cont.splice(indexOfObject, 1);
    await setMultipleEventDateTime(cont)
  }
  const onChangeDate = async (id, item) => {
    let cont = multipleEventDateTime
    cont[id].date = item
    await setMultipleEventDateTime(cont)
  }
  const onChangeStartTime = async (id, item) => {
    let cont = multipleEventDateTime
    cont[id].startTime = item
    await setMultipleEventDateTime(cont)
  }
  const onChangeEndTime = async (id, item) => {
    let cont = multipleEventDateTime
    cont[id].endTime = item
    await setMultipleEventDateTime(cont)
  }
  const onChangeCheckBox = async (item) => {
    setStopSellingAtStart(item)
  }
  const handleTicketAdd = async (id) => {
    await setTicketType([...ticketType, { id: id, type: '', price: 0 }])
  }
  const handleTicketDelete = async (id) => {
    let cont = ticketType
    const indexOfObject = cont.findIndex(object => {
      return object.id === id;
    });
    await cont.splice(indexOfObject, 1);
    await setTicketType(cont)
  }
  const onChangeTicketType = async (id, item) => {
    let cont = ticketType
    cont[id].type = item
    await setTicketType(cont)
  }
  const onChangeTicketPrice = async (id, item) => {
    let cont = ticketType
    cont[id].price = item
    await setTicketType(cont)
  }
  const handleImg1Edit = e => {
    // console.log('imggg', e.target.files)
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg({ ...img, img1: e.target.files[0] })
      setImgEdits({ ...imgEdits, img1: URL.createObjectURL(e.target.files[0]) })
    };
  }
  const handleImg2Edit = e => {
    // console.log('img data', e.target.files)
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg({ ...img, img2: e.target.files[0] })
      setImgEdits({ ...imgEdits, img2: URL.createObjectURL(e.target.files[0]) })
    };
  }
  const handleImg3Edit = e => {
    // console.log('img data', e.target.files)
    let image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      setImg({ ...img, img3: e.target.files[0] })
      setImgEdits({ ...imgEdits, img3: URL.createObjectURL(e.target.files[0]) })
    };
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setEventDurationErr('');
    setLoading(true);
    if (formStep === '1') {
      try {
        Number(noOfTickets)
      } catch (error) {
        setNoOfTicketsErr('Number of tickets must be a number')
        setLoading(false);
        return;
      }
      setFormStep('2');
      setstep2Available(true)
      setLoading(false);
      // console.log('step 1', eventName, description, noOfTickets, img)
      return
    } else if (formStep === '2') {
      setPlaceErr('');
      setErrMsg('')
      if (!eventType) {
        setErrMsg("Event Type can not be empty")
        setLoading(false);
        return;
      }
      if (!eventDuration) {
        setEventDurationErr("Event Duration can not be empty")
        setLoading(false);
        return;
      }
      if (Number(eventDuration) === 2) {
        // console.log('inside eventDuration', eventDuration)
        if (multipleEventDateTime.length === 1) {
          setEventDurationErr("Click ( + ) icon below to add more than one date or change Event Duration to One day event")
          setLoading(false);
          return;
        }
      }
      setFormStep('3');
      setstep3Available(true);
      setLoading(false);
      // console.log('step 2', eventType, eventDuration, eventLocation, eventUrlContent, eventDate, eventStartTime, eventEndTime, stopSellingAtStart, multipleEventDateTime)
      return
    } else if (formStep === '3') {
      // console.log('step 3 data', currency, ticketType, publishEvent)
      const formData = new FormData();
      formData.append('eventName', eventName);
      formData.append('description', description);
      if (noOfTickets) {
        formData.append('noOfTickets', Number(noOfTickets));
      }
      if (img) {
        if (isEdit) {
          if (img.img1) {
            formData.append('img1', img.img1);
          }
          if (img.img2) {
            formData.append('img2', img.img2);
          }
          if (img.img3) {
            formData.append('img3', img.img3);
          }
          formData.append('oldImgs', eventDetails.posters);
        } else {
          img.forEach((item, index) => {
            const indx = index + 1
            formData.append(`img${indx}`, item);
          });
        }
      }
      formData.append('eventType', Number(eventType));
      if (Number(eventType) === 1) {
        if (eventLocation.hasOwnProperty('value')) {
          await geocodeByPlaceId(eventLocation.value.place_id)
            .then(results => {
              // console.log('Location details', results)
              const ldt = results[0].address_components
              ldt.forEach(element => {
                if (element.types[0] === "locality" && element.types[1] === "political") {
                  formData.append('town', element.long_name);
                }
                if (element.types[0] === "country" && element.types[1] === "political") {
                  formData.append('country', element.long_name);
                }
              });
              getLatLng(results[0])
                .then(({ lat, lng }) => {
                  // console.log('Successfully got latitude and longitude', { lat, lng })
                  const cordss = {
                    latitude: lat,
                    longitude: lng,
                  }
                  formData.append('locationCordinates', JSON.stringify(cordss));
                })
                .catch(error => {
                  // console.error(error)
                  setPlaceErr('Incorrect location, Select from auto-suggestions provided')
                  setFormStep('2');
                  setLoading(false);
                  return;
                });
            })
            .catch(error => {
              // console.error(error)
              setPlaceErr('Incorrect location, Select from auto-suggestions provided')
              setFormStep('2');
              setLoading(false);
              return;
            });
            formData.append('eventLocation', eventLocation.label);
        } else {
          // ask user to select from the autocomplete values
          if (!isEdit) {
          setPlaceErr('Incorrect location, Select from auto-suggestions provided')
          setFormStep('2');
          setLoading(false);
          return;
          }
        }
      }
      if (Number(eventType) === 2) {
        formData.append('eventLocation', JSON.stringify(eventUrlContent));
      }
      if (Number(eventType) === 3) {
        if (eventLocation.value.place_id) {
          await geocodeByPlaceId(eventLocation.value.place_id)
            .then(results => {
              // console.log('Location details', results)
              const ldt = results[0].address_components
              ldt.forEach(element => {
                if (element.types[0] === "locality" && element.types[1] === "political") {
                  formData.append('town', element.long_name);
                }
                if (element.types[0] === "country" && element.types[1] === "political") {
                  formData.append('country', element.long_name);
                }
              });
              getLatLng(results[0])
                .then(({ lat, lng }) => {
                  // console.log('Successfully got latitude and longitude', { lat, lng })
                  const cordss = {
                    latitude: lat,
                    longitude: lng,
                  }
                  formData.append('locationCordinates', JSON.stringify(cordss));
                })
                .catch(error => {
                  // console.error(error)
                  setPlaceErr('Incorrect location, Select from auto-suggestions provided')
                  setFormStep('2');
                  setLoading(false);
                  return;
                });
            })
            .catch(error => {
              // console.error(error)
              setPlaceErr('Incorrect location, Select from auto-suggestions provided')
              setFormStep('2');
              setLoading(false);
              return;
            });
        } else {
          // ask user to select from the autocomplete values
          setPlaceErr('Incorrect location, Select from auto-suggestions provided')
          setFormStep('2');
          setLoading(false);
          return;
        }
        const lcdata = {
          physicalLocation: eventLocation.label,
          urls: eventUrlContent,
        };
        formData.append('eventLocation', JSON.stringify(lcdata));
      }
      formData.append('eventDuration', Number(eventDuration));
      const date1 = format(new Date(eventDate), 'yyyy-MM-dd');
      const time1 = format(new Date(eventStartTime), 'HH:mm');
      const time2 = format(new Date(eventEndTime), 'HH:mm');
      const oneDayEvent = {
        date: date1,
        startTime: time1,
        endTime: time2,
      }
      if (Number(eventDuration) === 1) {
        formData.append('eventDate', JSON.stringify(oneDayEvent));
      } else if (Number(eventDuration) === 2) {
        const fomartMultipleEventDateTime = []
        multipleEventDateTime.forEach(element => {
          const mdate1 = format(new Date(element.date), 'yyyy-MM-dd');
          const mtime1 = format(new Date(element.startTime), 'HH:mm');
          const mtime2 = format(new Date(element.endTime), 'HH:mm');
          fomartMultipleEventDateTime.push({ id: element.id, date: mdate1, startTime: mtime1, endTime: mtime2 })
        });
        formData.append('eventDate', JSON.stringify(fomartMultipleEventDateTime));
      }
      if (stopSellingAtStart) {
        formData.append('stopTime', 0);
      } else {
        formData.append('stopTime', 1);
      }
      formData.append('currency', currency);
      formData.append('ticketTypes', JSON.stringify(ticketType));
      if (publishEvent) {
        formData.append('status', 1);
      } else {
        formData.append('status', 0);
      }
      if (isEdit) {
        await authorizePatchMultipart(`event/${eventDetails.id}`, formData)
          .then(response => {
            // console.log('login message', response);
            Swal.fire({
              position: 'top-end',
              toast: true,
              width: 600,
              // background: '#F47E52',
              icon: 'success',
              titleText: response.message,
              showConfirmButton: true,
              timer: 5000,
            });
            if (response.events !== 0) {
              setData(response.events)
            }
            setShowReport(false);
            setshowForm(false);
          })
          .catch((error) => {
            // console.log('error logging in', error)
            Swal.fire({
              position: 'top-end',
              toast: true,
              width: 600,
              // background: '#F47E52',
              icon: 'error',
              titleText: error.message,
              showConfirmButton: true,
              timer: 5000,
            });
          });
      } else {
        await authorizePostMultipart('event', formData)
          .then(response => {
            // console.log('login message', response);
            Swal.fire({
              position: 'top-end',
              toast: true,
              width: 600,
              // background: '#F47E52',
              icon: 'success',
              titleText: response.message,
              showConfirmButton: true,
              timer: 5000,
            });
            if (response.events !== 0) {
              setData(response.events)
            }
            setShowReport(false);
            setshowForm(false);
          })
          .catch((error) => {
            // console.log('error logging in', error)
            Swal.fire({
              position: 'top-end',
              toast: true,
              width: 600,
              // background: '#F47E52',
              icon: 'error',
              titleText: error.message,
              showConfirmButton: true,
              timer: 5000,
            });
          });
      }
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid pt-4 px-4">
      <div className=" bg-secondary rounded  pb-5 p-4">
        {loading ? (
         <div id="spinner" className="show bg-dark position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
         <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
           <span className="sr-only">Loading...</span>
         </div>
       </div>
        ) : (
          <>
            {showForm ? (
              <div className='col-lg-12 row align-items-center justify-content-center'>
                <h6 className="mb-4 text-center">{isEdit ? 'Edit event details' : 'Create your event in 3 easy steps'}</h6>
                <div className='col-lg-8'>
                  <div class="row">
                    <div class="col">
                      <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                        <div class="timeline-step">
                          <div style={{ cursor: 'pointer' }} onClick={() => {
                            setFormStep('1');
                          }} class="timeline-content" >
                            <div class="inner-circle" style={{ backgroundColor: formStep === '1' ? 'red' : '' }}></div>
                            <p class="h6 mt-3 mb-1" style={{ color: formStep === '1' ? 'red' : '' }}>Step 1</p>
                            {/* <p class="h6 text-muted mb-0 mb-lg-0">Basic event Info</p> */}
                          </div>
                        </div>
                        <div class="timeline-step">
                          <div class="timeline-content" style={{ cursor: 'pointer' }} onClick={() => {
                            if (step2Available) {
                              setFormStep('2');
                            }
                          }}>
                            <div class="inner-circle" style={{ backgroundColor: formStep === '2' ? 'red' : '' }}></div>
                            <p class="h6 mt-3 mb-1" style={{ color: formStep === '2' ? 'red' : '' }}>Step 2</p>
                            {/* <p class="h6 text-muted mb-0 mb-lg-0">Launched Trello</p> */}
                          </div>
                        </div>
                        <div class="timeline-step">
                          <div class="timeline-content" style={{ cursor: 'pointer' }} onClick={() => {
                            if (step3Available) {
                              setFormStep('3');
                            }
                          }}>
                            <div class="inner-circle" style={{ backgroundColor: formStep === '3' ? 'red' : '' }}></div>
                            <p class="h6 mt-3 mb-1" style={{ color: formStep === '3' ? 'red' : '' }}>Step 3</p>
                            {/* <p class="h6 text-muted mb-0 mb-lg-0">Launched Messanger</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form method='post' onSubmit={onSubmit}>
                    {formStep === '1' && (
                      <>
                        <div className="form-floating mb-3">
                          <input type="text" style={{ backgroundColor: "#E5E5E5" }} value={eventName} onChange={(e) => setEventName(e.target.value)} className="form-control" id="floatingInput1" placeholder="My event title" required />
                          <label htmlFor="floatingInput1">Event Name</label>
                        </div>
                        <div className="form-floating mb-3">
                          <textarea style={{ backgroundColor: "#E5E5E5", height: 150 }} value={description} onChange={(e) => {
                            // how to add a character counter?
                            // console.log('char length', e.target.value.length)
                            const wcc = 155 - e.target.value.length
                            if (wcc >= 0) {
                              setWordCount(155 - e.target.value.length)
                              setDescription(e.target.value)
                            }
                          }} className="form-control" id="floatingTextarea"
                            placeholder="name@example.com" required></textarea>
                          <label htmlFor="floatingTextarea">Event Description</label>
                          <div className="form-text"><span className="text-primary">{wordCount}</span> characters remaining</div>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="number" style={{ backgroundColor: "#E5E5E5" }} value={noOfTickets} onChange={(e) => {
                            setNoOfTickets(e.target.value)
                          }} className="form-control" id="floatingInput1" placeholder="Number of tickets" />
                          <label htmlFor="floatingInput1">Number of tickets to sell</label>
                          <div className="form-text">To sell an infinite amount, leave number of tickets above empty</div>
                          {noOfTicketsErr && <div className="form-text text-danger">{noOfTicketsErr}</div>}
                        </div>
                        {isEdit ? (
                          <div className='row mb-3'>
                            <label htmlFor="floatingInput3" className="form-label">Event Posters</label>

                            <div className='col-lg-4'>
                              <label htmlFor="fileUpload1" class="btn btn-square btn-primary m-2"><i class="fa fa-edit"></i></label>
                              <input type="file" accept="image/*" multiple="false" onChange={handleImg1Edit}
                                id="fileUpload1" style={{ display: 'none' }} className="" />
                              {imgEdits.img1 && (
                                <img alt="img" src={imgEdits.img1} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                              )}
                            </div>

                            <div className='col-lg-4'>
                              <label htmlFor="fileUpload2" class="btn btn-square btn-primary m-2"><i class="fa fa-edit"></i></label>
                              <input type="file" accept="image/*" multiple="false" onChange={handleImg2Edit}
                                id="fileUpload2" style={{ display: 'none' }} className="" />
                              {imgEdits.img2 && (
                                <img alt="img" src={imgEdits.img2} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                              )}
                            </div>

                            <div className='col-lg-4'>
                              <label htmlFor="fileUpload3" class="btn btn-square btn-primary m-2"><i class="fa fa-edit"></i></label>
                              <input type="file" accept="image/*" multiple="false" onChange={handleImg3Edit}
                                id="fileUpload3" style={{ display: 'none' }} className="" />
                              {imgEdits.img3 && (
                                <img alt="img" src={imgEdits.img3} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <label htmlFor="floatingInput3" className="form-label">Event Posters</label>
                            <input type="file" accept="image/*"
                              multiple onChange={handleImgChange} className="form-control bg-dark" id="floatingInput3"
                              placeholder="name@example.com" />
                            <div className="form-text">3 Images Max & less than 30 MBs total</div>
                            {imgerr && <div className="form-text text-danger">{imgerr}</div>}
                            {imgPreview.length >= 1 && (
                              <>
                                <div className='row'>
                                  {imgPreview.map((item, index) => (
                                    <div className='col-lg-4' >
                                      <img key={index} alt="img" src={item} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        <button type="submit" className="btn btn-primary py-3 w-100 mb-4">Next Step</button>
                      </>
                    )}
                    {formStep === '2' && (
                      <>
                        <div className="mb-3">
                          <label htmlFor="floatingInput1" className="form-label">Event Type</label>
                          <select value={eventType} onChange={(e) => {
                            setEventType(e.target.value)
                            setErrMsg('');
                          }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                            <option>Select</option>
                            <option value="1">Physical Event</option>
                            <option value="2">Online Event</option>
                            <option value="3">Both (Hybrid Event)</option>
                          </select>
                          {errMsg && <div className="form-text text-danger">{errMsg}</div>}
                        </div>
                        {Number(eventType) === 1 && (
                          <div className="mb-3">
                            <label htmlFor="floatingInput1" className="form-label">Event Location</label>
                            {isEdit && <label htmlFor="floatingInput12z" className="form-label text-success">{`Current Location : ${eventLocation}`}</label>}
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyCll22dL7FTg4Jk4tRsrnbd9PHTZy6WXB0"
                              minLengthAutocomplete={3}
                              onLoadFailed={(error) => (
                                console.error("network err", error)
                              )}
                              selectProps={{
                                value: eventLocation,
                                onChange: setEventLocation,
                                // className: "form-control bg-dark",
                                noOptionsMessage: () => "Start typing to get Google maps place suggestions for you to select",
                                required: true,
                              }}
                            />
                            {placeErr && <div className="form-text text-danger">{placeErr}</div>}
                          </div>
                        )}
                        {Number(eventType) === 2 && (
                          <OnlineEventURL onlineEventUrls={eventUrlContent} handleUrlAdd={handleUrlAdd} handleUrlDelete={handleUrlDelete} onChangeUrl={onChangeUrl} />
                        )}
                        {Number(eventType) === 3 && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="floatingInput1" className="form-label">Event Location</label>
                              <GooglePlacesAutocomplete
                                apiKey="AIzaSyCll22dL7FTg4Jk4tRsrnbd9PHTZy6WXB0"
                                minLengthAutocomplete={3}
                                onLoadFailed={(error) => (
                                  console.error("network err", error)
                                )}
                                selectProps={{
                                  value: eventLocation,
                                  onChange: setEventLocation,
                                  // className: "form-control bg-dark",
                                  noOptionsMessage: () => "Google maps place suggestions will appear here for you to select",
                                  required: true,
                                }}
                              />
                              {placeErr && <div className="form-text text-danger">{placeErr}</div>}
                            </div>
                            <OnlineEventURL onlineEventUrls={eventUrlContent} handleUrlAdd={handleUrlAdd} handleUrlDelete={handleUrlDelete} onChangeUrl={onChangeUrl} />
                          </>
                        )}

                        <div className="mb-3">
                          <label htmlFor="floatingInput1" className="form-label">Event Duration</label>
                          <select value={eventDuration} onChange={(e) => {
                            setEventDuration(e.target.value)
                            setEventDurationErr('');
                          }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                            <option>Select</option>
                            <option value="1">Same day Event</option>
                            <option value="2">Multiple days</option>
                          </select>
                          {eventDurationErr && <div className="form-text text-danger">{eventDurationErr}</div>}
                        </div>
                        {Number(eventDuration) === 1 && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="floatingInput3" className="form-label">Event Date</label>
                              <DatePicker withPortal dateFormat="yyyy-MM-dd" selected={eventDate} disabledKeyboardNavigation showMonthDropdown
                                useLongMonthInDropdown minDate={new Date()} customInput={<DateCustomInput />} onChange={(date) => setEventDate(date)} />
                            </div>
                            <div className='row'>
                              <div className='col-lg-6' >
                                <div className="mb-3">
                                  <label htmlFor="floatingInput3" className="form-label">Event Start Time</label>
                                  <DatePicker selected={eventStartTime} disabledKeyboardNavigation showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa" customInput={<DateCustomInput />} onChange={(time) => setEventStartTime(time)} />
                                </div>
                                <div class="mb-3 form-check">
                                  <input type="checkbox" onChange={async (e) => setStopSellingAtStart(true)} checked={stopSellingAtStart === true ? true : false} class="form-check-input" id="exampleCheck1" />
                                  <label class="form-check-label" htmlFor="exampleCheck1">Stop selling tickets at Start Time</label>
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <div className="mb-3">
                                  <label htmlFor="floatingInput3" className="form-label">Event End Time</label>
                                  <DatePicker selected={eventEndTime} disabledKeyboardNavigation showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa" customInput={<DateCustomInput />} onChange={(time) => setEventEndTime(time)} />
                                </div>
                                <div class="mb-3 form-check">
                                  <input type="checkbox" onChange={async (e) => setStopSellingAtStart(false)} checked={stopSellingAtStart === false ? true : false} class="form-check-input" id="exampleCheck2" />
                                  <label class="form-check-label" htmlFor="exampleCheck2">Stop selling tickets at End Time</label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {Number(eventDuration) === 2 && (
                          <EventDateTime dateTimeData={multipleEventDateTime} stopSellingAtStart={stopSellingAtStart} handleDateTimeAdd={handleDateTimeAdd} handleDateTimeDelete={handleDateTimeDelete} onChangeDate={onChangeDate} onChangeStartTime={onChangeStartTime} onChangeEndTime={onChangeEndTime} onChangeCheckBox={onChangeCheckBox} />
                        )}
                        <button type="submit" className="btn btn-primary py-3 w-100 mb-4 mt-3">Next Step</button>
                      </>
                    )}
                    {formStep === '3' && (
                      <>
                        {/* ticket types */}
                        <div className="mb-3">
                          <label htmlFor="floatingInput1" className="form-label">Ticket Currency</label>
                          <select value={currency} onChange={(e) => {
                            setCurrency(e.target.value)
                            setCurrencyErr('');
                          }} className="form-select form-control-lg" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                            <option>Select</option>
                            {allowedCurrencies.length >= 1 && (
                              <>
                                {allowedCurrencies.map((item, index) => (
                                  <option key={index} value={item.code}>{item.name}</option>
                                ))}
                              </>
                            )}
                          </select>
                          {currencyErr && <div className="form-text text-danger">{currencyErr}</div>}
                        </div>
                        <TicketType ticketTypeData={ticketType} handleTicketAdd={handleTicketAdd} handleTicketDelete={handleTicketDelete} onChangeTicketType={onChangeTicketType} onChangeTicketPrice={onChangeTicketPrice} />
                        <div class="mb-3 mt-3 form-check">
                          <input type="checkbox" onChange={async (e) => {
                            setPublishEvent(!publishEvent)
                          }} checked={publishEvent} class="form-check-input border-primary" id="exampleCheck1" />
                          <label class="form-check-label" for="exampleCheck1">Publish this event to public now (can be published later)</label>
                        </div>
                        <button type="submit" className="btn btn-primary py-3 w-100 mb-4 mt-3">Create Event</button>
                      </>
                    )}
                    <div className="text-center">
                      <button type="button" onClick={() => setshowForm(false)} className="btn btn-lg btn-lg-square btn-primary m-2"><i className="fa fa-times"></i></button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <>
                {showReport ? (
                  <>
                    <div className="m-n2">
                      <button onClick={() => setShowReport(false)} type="button" class="btn btn-square btn-primary"><i class="fa fa-arrow-left"></i></button>

                      <button type="button" className="btn btn-primary m-2" onClick={() => {
                        setIsEdit(true);
                        setEventName(eventDetails.eventName)
                        setDescription(eventDetails.description)
                        if (eventDetails.noOfTickets) {
                          setNoOfTickets(eventDetails.noOfTickets)
                        }
                        setImgEdits(JSON.parse(eventDetails.posters))
                        setEventType(`${eventDetails.eventType}`)
                        if (eventDetails.eventType === 1) {
                          setEventLocation(eventDetails.eventLocation)
                        } else if (eventDetails.eventType === 2) {
                          setEventUrlContent(JSON.parse(eventDetails.eventLocation))
                        } else if (eventDetails.eventType === 3) {
                          setEventLocation(JSON.parse(eventDetails.eventLocation).physicalLocation)
                          setEventUrlContent(JSON.parse(eventDetails.eventLocation).urls)
                        }
                        setEventDuration(`${eventDetails.eventDuration}`)
                        if (eventDetails.eventDuration === 1) {
                          setEventDate(new Date(`${JSON.parse(eventDetails.eventDate).date}T${JSON.parse(eventDetails.eventDate).startTime}:00.000Z`))
                          setEventStartTime(new Date(`${JSON.parse(eventDetails.eventDate).date}T${JSON.parse(eventDetails.eventDate).startTime}:00.000Z`))
                          setEventEndTime(new Date(`${JSON.parse(eventDetails.eventDate).date}T${JSON.parse(eventDetails.eventDate).endTime}:00.000Z`))
                        } else if (eventDetails.eventDuration === 2) {
                          const evdtt = JSON.parse(eventDetails.eventDate)
                          let evdtt1 = []
                          evdtt.forEach(element => {
                            evdtt1.push({ ...element, date: new Date(`${element.date}T${element.startTime}:00.000Z`), startTime: new Date(`${element.date}T${element.startTime}:00.000Z`), endTime: new Date(`${element.date}T${element.endTime}:00.000Z`) })
                          });
                          setMultipleEventDateTime(evdtt1)
                        }
                        if (eventDetails.stopTime === 1) {
                          setStopSellingAtStart(true)
                        } else {
                          setStopSellingAtStart(false)
                        }
                        setCurrency(eventDetails.currency)
                        if (Number(eventDetails.status) === 1) {
                          setPublishEvent(true)
                        } else {
                          setPublishEvent(false)
                        }
                        setTicketType(JSON.parse(eventDetails.ticketTypes))
                        setFormStep('1');
                        setstep2Available(true)
                        setstep3Available(true)
                        setshowForm(true)
                        // console.log('dttt', eventDetails);
                      }}><i className="fa fa-edit me-2"></i>Edit Event Details</button>
                    </div>

                    <h6 className="mb-4 mt-4 text-primary">Event Details</h6>
                    <div className='row'>
                      {JSON.parse(eventDetails.posters).img1 && (
                        <div className='col-lg-4' >
                          <img alt="img" src={JSON.parse(eventDetails.posters).img1} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                        </div>
                      )}
                      {JSON.parse(eventDetails.posters).img2 && (
                        <div className='col-lg-4' >
                          <img alt="img" src={JSON.parse(eventDetails.posters).img2} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                        </div>
                      )}
                      {JSON.parse(eventDetails.posters).img3 && (
                        <div className='col-lg-4' >
                          <img alt="img" src={JSON.parse(eventDetails.posters).img3} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                        </div>
                      )}
                    </div>
                    <p className="h4 mt-4">Link To Your Event (Share on social media)</p>
                    <p>{`https://tickets2go.net/event-details?code=${eventDetails.eventCode}&&${eventDetails.eventName.toString().toLowerCase().replace(/\s/g, '')}`}</p>
                    <p className="h4 mt-4">Event Code (Share on social media)</p>
                    <p>{eventDetails.eventCode}</p>
                    <p className="h4 mt-4">Event Name</p>
                    <p>{eventDetails.eventName}</p>
                    <p className="h4">Event Description</p>
                    <p>{eventDetails.description}</p>
                    <p className="h4">Currency</p>
                    <p>{eventDetails.currency}</p>
                    <p className="h4">Total revenue collected</p>
                    <p>{eventDetails.revenue}</p>
                    <p className="h4">Number of tickets to sell</p>
                    <p>{eventDetails.noOfTickets || 'infinite'}</p>
                    <p className="h4">Tickets sold</p>
                    <p>{eventDetails.ticketsSold}</p>
                    <p className="h4">Event Type</p>
                    <p>{eventDetails.eventType === 1 ? 'Physical Event' : eventDetails.eventType === 2 ? 'Online Event' : 'Hybrid Event'}</p>
                    {eventDetails.eventType === 1 && (
                      <>
                        <p className="h4">Event Location</p>
                        <p>{eventDetails.eventLocation}</p>
                      </>
                    )}
                    {eventDetails.eventType === 2 && (
                      <>
                        <p className="h4">Links to online Event</p>
                        {JSON.parse(eventDetails.eventLocation).map((item, index) => (
                          <p key={index}>{item.url}</p>
                        ))}
                      </>
                    )}
                    {eventDetails.eventType === 3 && (
                      <>
                        <p className="h4">Event Location</p>
                        <p>{JSON.parse(eventDetails.eventLocation).physicalLocation}</p>
                        <p className="h4">Links to online Event</p>
                        {JSON.parse(eventDetails.eventLocation).urls.map((item, index) => (
                          <p key={index}>{item.url}</p>
                        ))}
                      </>
                    )}
                    <p className="h4">Event Duration</p>
                    <p>{eventDetails.eventDuration === 1 ? 'Same day event' : 'Multiple day event'}</p>
                    {eventDetails.eventDuration === 1 ? (
                      <>
                        <p className="h4">Event Date</p>
                        <p>{format(new Date(JSON.parse(eventDetails.eventDate).date), 'PPPP')}</p>
                        <p className="h4">Event Start Time</p>
                        <p>{format(new Date(`${JSON.parse(eventDetails.eventDate).date}T${JSON.parse(eventDetails.eventDate).startTime}:00.000Z`), 'pppp')}</p>
                        <p className="h4">Event End Time</p>
                        <p>{format(new Date(`${JSON.parse(eventDetails.eventDate).date}T${JSON.parse(eventDetails.eventDate).endTime}:00.000Z`), 'pppp')}</p>
                      </>
                    ) : (
                      <>
                        {JSON.parse(eventDetails.eventDate).map((item, index) => {
                          const indd = index + 1;
                          return (
                            <>
                              <p key={index} className="h4">{`Event ${indd} Date`}</p>
                              <p>{item.date}</p>
                              <p>{format(new Date(item.date), 'PPPP')}</p>
                              <p className="h4">{`Event ${indd} Start Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.startTime}:00.000Z`), 'pppp')}</p>
                              <p className="h4">{`Event ${indd} End Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.endTime}:00.000Z`), 'pppp')}</p>
                            </>
                          )
                        })}
                      </>
                    )}
                    <p className="h4">Stop time</p>
                    <p>{eventDetails.stopTime === 0 ? 'Start of event' : 'End of event'}</p>
                    <p className="h4">Tickets</p>
                    {JSON.parse(eventDetails.ticketTypes).map((item, index) => (
                      <p key={index}>{`Ticket Type : ${item.type}  ,  Price : ${item.price}`}</p>
                    ))}
                  </>
                ) : (
                  <>
                    <button type="button" className="btn btn-primary" onClick={() => {
                      setIsEdit(false);
                      setEventName('')
                      setDescription('')
                      setNoOfTickets()
                      setImgEdits({})
                      setEventType('')
                      setEventLocation('')
                      setEventUrlContent([{ id: 0, url: '' }])
                      setEventDuration('')
                      setEventDate(new Date())
                      setEventStartTime(new Date())
                      setEventEndTime(new Date())
                      setMultipleEventDateTime([
                        { id: 0, date: new Date(), startTime: new Date(), endTime: new Date(), },
                        { id: 1, date: new Date(), startTime: new Date(), endTime: new Date(), }
                      ])
                      setStopSellingAtStart(true)
                      setCurrency('')
                      setPublishEvent(false)
                      setTicketType([
                        { id: 0, type: '', price: 0 }
                      ])
                      setFormStep('1');
                      setstep2Available(false)
                      setstep3Available(false)
                      setshowForm(true)
                    }}><i className="fa fa-plus me-2"></i>Create Event</button>

                    <div className="table-responsive mt-4">
                      <h6 className="mb-4">Events Organised By Me</h6>
                      <table className="table table-dark">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col" colSpan={2}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data ? (
                            <>
                              {data.map((item, index) => {
                                const imgs = JSON.parse(item.posters)
                                let dt = JSON.parse(item.eventDate)
                                // console.log('dt', dt)
                                if (item.eventDuration === 2) {
                                  const dt1 = dt[0].date.split('T')[0]
                                  const dt2 = dt[dt.length - 1].date.split('T')[0]
                                  dt = `${dt1} to ${dt2}`
                                }
                                // const dt = item.eventDate.split('T')[0]
                                // const ftime1 = format(new Date(`${item.eventDate}T${item.eventTime}.000Z`), 'hh:mm aaa')
                                // const tt = item.eventTime.split(':')
                                return (
                                  <tr style={{ cursor: 'pointer' }} key={index}>
                                    <th onClick={() => {
                                      setEventDetails(item);
                                      console.log('item', item)
                                      setShowReport(true);
                                    }} scope="row"><img src={imgs.img1} style={{ objectFit: 'contain' }} alt="Event poster" width="150" height="150" /></th>
                                    <td onClick={() => {
                                      setEventDetails(item);
                                      setShowReport(true);
                                    }}>{item.eventName}</td>
                                    <td onClick={() => {
                                      setEventDetails(item);
                                      setShowReport(true);
                                    }}>{item.eventDuration === 2 ? dt : dt.date}</td>
                                    <td onClick={() => {
                                      setEventDetails(item);
                                      setShowReport(true);
                                    }}>{item.status === 1 ? 'Published' : item.status === 0 ? 'UnPublished' : item.status === 2 ? 'Sold Out' : item.status === 3 ? 'Blocked' : 'Finished'}</td>
                                    <td><button onClick={() => {
                                      setEventDetails(item);
                                      setshowForm(true)
                                    }} type="button" class="btn btn-square btn-primary"><i class="fa fa-edit"></i></button></td>
                                    <td><button type="button" class="btn btn-square btn-primary"><i class="fa fa-trash-alt"></i></button></td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">Events you create will appear here</td>
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Events
